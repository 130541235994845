import Form from 'hew/Form';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Spinner from 'components/Spinner';
import VersionPicker from 'components/VersionPicker';
import { useFetchWithRetry } from 'hooks/useFetch';
import { upgradeCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import { SemanticVersion } from 'types';
import handleError, { ErrorLevel } from 'utils/error';
import { suitableVersions } from 'utils/saas';
import { versionToString } from 'utils/string';

interface Props {
  onClose?: () => void;
  cluster: GlobalApi.ModelClusterInfo;
  orgId: string;
  supportedDetVersions: string[];
}

interface FormValues {
  desiredVersion?: SemanticVersion;
}

export const _UpgradeClusterVersionModal: React.FC<Props> = ({
  cluster,
  supportedDetVersions,
  onClose,
  orgId,
}) => {
  const [canceler] = useState(() => new AbortController());
  const fetchWithRetry = useFetchWithRetry(canceler);
  const [inputs, setInputs] = useState<FormValues>({ desiredVersion: undefined });

  const handleSubmit = useCallback(async () => {
    try {
      await fetchWithRetry(async () => {
        if (!inputs || !inputs.desiredVersion) return;
        await upgradeCluster(
          {
            clusterId: cluster.id,
            orgId,
            regionId: cluster.location,
            version: versionToString(inputs.desiredVersion),
          },
          { signal: canceler.signal },
        );
      });
    } catch (error) {
      throw handleError(error, {
        level: ErrorLevel.Error,
        publicSubject: 'Failed to request upgrade',
        silent: false,
      });
    }
  }, [canceler.signal, cluster.id, cluster.location, inputs, fetchWithRetry, orgId]);

  const validUpgrades = useMemo(() => {
    return suitableVersions(supportedDetVersions, cluster.detVersion || '');
  }, [cluster.detVersion, supportedDetVersions]);

  const submitDisabled =
    inputs.desiredVersion === undefined ||
    versionToString(inputs.desiredVersion) === cluster.detVersion;

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      icon="warning"
      size="medium"
      submit={{
        disabled: submitDisabled,
        handleError,
        handler: handleSubmit,
        text: 'Ok',
      }}
      title={`Upgrade version for ${cluster.name}`}
      onClose={() => {
        onClose?.();
      }}>
      {validUpgrades.length ? (
        <Form>
          <Form.Item label="Desired version" name="desiredVersion">
            <VersionPicker
              versions={validUpgrades}
              onChange={(ver) => setInputs((cur) => ({ ...cur, desiredVersion: ver }))}
            />
          </Form.Item>
        </Form>
      ) : (
        <Spinner tip="No upgrades are available." />
      )}
    </Modal>
  );
};
