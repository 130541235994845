import { Divider } from 'antd';
import Form from 'hew/Form';
import Input from 'hew/Input';
import { Modal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import RegionPicker from 'components/RegionPicker';
import { useStore } from 'contexts/Store';
import { useMakeClusterCreateCall } from 'hooks/useFetch';
import { OverallConfig } from 'saasTypes';
import { byokClusterCreationPrecheck } from 'services/api';
import {
  ModelAuxiliaryResources,
  ModelByokSupportMatrix,
  ModelCreateClusterRequest,
  ModelMasterConfig,
} from 'services/regional-bindings';
import handleError from 'utils/error';
import { generateByokMasterConfig, getLatestVersion } from 'utils/saas';

import AdvancedConfigForm from './AdvancedConfig';
import css from './NewByokMldeClusterModal.module.scss';
import { initialState } from './NewByokMldeClusterModal.settings';
import { validateClusterRequestObject } from './utils';

interface Props {
  onFinish: () => Promise<void>;
  isModalOpen?: boolean;
  onClose: () => void;
}

export interface NetworkingParams {
  ipRange: string;
  private0: string;
  private1: string;
  public: string;
}

export const _NewByokMldeClusterModal: React.FC<Props> = ({
  onFinish,
  isModalOpen = false,
  onClose,
}) => {
  const [form] = Form.useForm();
  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();

  const [canceler] = useState(() => new AbortController());
  const makeClusterCreateCall = useMakeClusterCreateCall(canceler);

  const [clusterName, setClusterName] = useState<string>('');
  const [clusterRegion, setClusterRegion] = useState<string>('');
  const [masterConfig, setMasterConfig] = useState<ModelMasterConfig>(() =>
    generateByokMasterConfig(initialState.poolConfigs, initialState.resourceManager),
  );
  const [availableNodes, setAvailableNodes] = useState<ModelByokSupportMatrix>({
    availableNodes: [],
  });
  const [supportedDetVersions, setSupportedDetVersions] = useState<string[]>([]);
  const [overallConfig, setOverallConfig] = useState<OverallConfig>(initialState.overallConfig);
  const [auxiliaryResources, setAuxiliaryResources] = useState<ModelAuxiliaryResources>(
    initialState.auxiliaryResources,
  );

  useEffect(() => {
    if (supportMatrix) {
      setSupportedDetVersions(supportMatrix.supportedDetVersions);
    }
  }, [supportMatrix]);

  useEffect(() => {
    const availableNodesPrecheck = async () => {
      if (selectedOrg?.id !== undefined && selectedOrg?.id !== '') {
        try {
          const { supportMatrix: byokSupportMatrix } = await byokClusterCreationPrecheck(
            {
              orgId: selectedOrg?.id || '',
              regionId: 'aws-us-west-2',
            },
            { signal: canceler.signal },
          );
          setAvailableNodes(byokSupportMatrix);
        } catch (e) {
          // tslint:disable:no-empty
        }
      }
    };

    if (isModalOpen) {
      availableNodesPrecheck();
    }
  }, [canceler, isModalOpen, selectedOrg?.id]);

  const handleSubmit = useCallback(() => {
    // Create the request object with all required creation parameters
    // const clusterRequest = makeBasicClusterRequestObject(basicStandardClusterRequest);
    const clusterRequest: ModelCreateClusterRequest = {
      ...overallConfig,

      appVersions: {
        mlde: overallConfig.detVersion,
      },

      auxiliaryResources: {
        dbCpuReq: '1',
        dbMemReq: '2Gi',
        dbName: 'test-db',
        dbStorageSize: '10Gi',
        ...auxiliaryResources,
      },
      // TODO: temporary workaround
      ipRange: '10.0.0.0/20',
      masterConfig: masterConfig,
      name: clusterName,
      subnetRanges: {},
    };

    validateClusterRequestObject(clusterRequest);
    return makeClusterCreateCall(clusterRegion, clusterRequest, onFinish);
  }, [
    clusterName,
    clusterRegion,
    makeClusterCreateCall,
    onFinish,
    overallConfig,
    masterConfig,
    auxiliaryResources,
  ]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  useEffect(() => {
    const latestVersion = getLatestVersion(supportedDetVersions);
    if (overallConfig.detVersion === '' && latestVersion !== undefined) {
      // set default detVersion value once supportedDetVersions is loaded:
      setOverallConfig((c) => ({
        ...c,
        detVersion: latestVersion,
      }));
    }
  }, [supportedDetVersions, overallConfig.detVersion]);

  const modalContent = useMemo(() => {
    return (
      <div className={css.modalContent}>
        <Form form={form} labelCol={{ span: 24 }}>
          {/* TODO: need to address with the regional service split */}
          <Form.Item label="Region" name="region">
            <RegionPicker
              curRegion={clusterRegion}
              id="region"
              regionFilter={(region) => region.startsWith('byok')}
              onChange={setClusterRegion}
            />
          </Form.Item>
          <Form.Item
            label="Cluster Name"
            name="clusterName"
            rules={[{ message: 'Cluster name required', required: true }]}>
            <Input
              value={clusterName}
              onChange={(e) => {
                setClusterName(e.target.value);
              }}
            />
          </Form.Item>
          <Divider />
        </Form>
        <AdvancedConfigForm
          form={form}
          overallConfig={overallConfig}
          supportedDetVersions={supportedDetVersions}
          supportMatrix={availableNodes}
          updateMasterConfig={setMasterConfig}
          onUpdateAuxiliaryConfig={setAuxiliaryResources}
          onUpdateOverallConfig={setOverallConfig}
        />
      </div>
    );
  }, [
    clusterName,
    clusterRegion,
    form,
    availableNodes,
    supportedDetVersions,
    overallConfig,
    setOverallConfig,
    setMasterConfig,
  ]);

  const submitDisabled = useMemo(
    () =>
      !clusterName ||
      !masterConfig ||
      masterConfig.resource_manager.default_aux_resource_pool === '' ||
      masterConfig.resource_manager.default_compute_resource_pool === '' ||
      masterConfig.resource_pools.length === 0,
    [clusterName, masterConfig],
  );

  return (
    <Modal
      cancel={true}
      cancelText="Cancel"
      size="medium"
      submit={{
        disabled: submitDisabled,
        handleError,
        handler: handleSubmit,
        text: 'Create Cluster',
      }}
      title="New Cluster"
      onClose={onClose}>
      {modalContent}
    </Modal>
  );
};
