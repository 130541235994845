import { MenuOption } from 'hew/Dropdown';
import { RouteProps } from 'react-router-dom';

import { Roles } from 'saasTypes';
import { ModelUserLicensingStatusResponse } from 'services/global-bindings';

export * from 'types';
export type PropsWithStoragePath<T> = T & { storagePath?: string };

export interface User {
  email: string;
  licensing: ModelUserLicensingStatusResponse;
  name: string;
  roles: Roles;
  superAdmin: boolean;
  userId: string;
}

export type MenuOptionWithOnClick = MenuOption & {
  onClick: () => void | Promise<void>;
};

export enum BrandingType {
  Determined = 'determined',
  HPE = 'hpe',
}

export enum RunState {
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  StoppingCanceled = 'STOPPING_CANCELED',
  Canceled = 'CANCELED',
  StoppingCompleted = 'STOPPING_COMPLETED',
  Completed = 'COMPLETED',
  StoppingError = 'STOPPING_ERROR',
  Errored = 'ERROR',
  Deleted = 'DELETED',
  Deleting = 'DELETING',
  DeleteFailed = 'DELETE_FAILED',
  Unspecified = 'UNSPECIFIED',
}

export interface Auth {
  isAuthenticated: boolean;
  user?: User;
}

export interface SsoProvider {
  name: string;
  ssoUrl: string;
}

export interface Telemetry {
  enabled: boolean;
  segmentKey?: string;
}

export enum ResourceState { // This is almost CommandState
  Unspecified = 'UNSPECIFIED',
  Assigned = 'ASSIGNED',
  Pulling = 'PULLING',
  Starting = 'STARTING',
  Running = 'RUNNING',
  Terminated = 'TERMINATED',
  Warm = 'WARM',
}

// High level Slot state
export enum SlotState {
  Running = 'RUNNING',
  Free = 'FREE',
  Pending = 'PENDING',
  Potential = 'POTENTIAL',
}

export interface EndTimes {
  endTime?: string;
}

export interface StartEndTimes extends EndTimes {
  startTime: string;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export const ALL_VALUE = 'all';

export enum LogLevel {
  Critical = 'critical',
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  None = 'none',
  Trace = 'trace',
  Warning = 'warning',
}

export enum LogLevelFromApi {
  Unspecified = 'LOG_LEVEL_UNSPECIFIED',
  Trace = 'LOG_LEVEL_TRACE',
  Debug = 'LOG_LEVEL_DEBUG',
  Info = 'LOG_LEVEL_INFO',
  Warning = 'LOG_LEVEL_WARNING',
  Error = 'LOG_LEVEL_ERROR',
  Critical = 'LOG_LEVEL_CRITICAL',
}

export interface Log {
  id: number | string;
  level?: LogLevel;
  message: string;
  meta?: string;
  time: string;
}

export enum AWSBackendProviderFields {
  XAcctRole = 'xAcctRole',
  AgentInstanceProfile = 'agentInstanceProfile',
  MasterInstanceProfile = 'masterInstanceProfile',
}

export enum GKEBackendProviderFields {
  ProjectId = 'projectId',
}

export enum BYOK8SBackendProviderFields {
  Kubeconfig = 'kubeconfig',
}

export type Primitive = boolean | number | string;
export type RecordKey = string | number | symbol;
export type UnknownRecord = Record<RecordKey, unknown>;
export type NullOrUndefined<T = undefined> = T | null | undefined;
export type Point = { x: number; y: number };
export type Range<T = Primitive> = [T, T];
export type Eventually<T> = T | Promise<T>;
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type RawJson = Record<string, any>;

export interface Pagination {
  limit: number;
  offset: number;
  total?: number;
}

export interface FetchOptions {
  signal?: AbortSignal;
}

interface ApiBase {
  name: string;
  stubbedResponse?: unknown;
  unAuthenticated?: boolean;
  // middlewares?: Middleware[]; // success/failure middlewares
}

// Designed for use with Swagger generated api bindings.
export interface DetApi<Input, DetOutput, Output> extends ApiBase {
  postProcess: (response: DetOutput) => Output;
  request: (params: Input, options?: FetchOptions) => Promise<DetOutput>;
  stubbedResponse?: DetOutput;
}

/**
 * @description helper to organize storing api response data.
 */
export interface ApiState<T> {
  data?: T;
  /**
   * error, if any, with the last state update.
   * this should be cleared on the next successful update.
   */
  error?: Error;
  /**
   * indicates whether the state has been fetched at least once or not.
   * should always be initialized to false.
   */
  hasBeenInitialized?: boolean;
  /** is the state being updated? */
  isLoading?: boolean;
}

export interface SingleEntityParams {
  id: number;
}

/* eslint-disable-next-line @typescript-eslint/ban-types */
export type EmptyParams = {};

/**
 * Router Configuration
 * If the component is not defined, the route is assumed to be an external route,
 * meaning React will attempt to load the path outside of the internal routing
 * mechanism.
 */
export type RouteConfig = {
  icon?: string;
  id: string;
  needAuth?: boolean;
  path: string;
  popout?: boolean;
  suffixIcon?: string;
  title?: string;
} & RouteProps;

export interface ClassNameProp {
  /** classname to be applied to the base element */
  className?: string;
}
export interface CommonProps extends ClassNameProp {
  children?: React.ReactNode;
  title?: string;
}

export interface SemanticVersion {
  major: number;
  minor: number;
  patch: number;
  rc?: string;
}

export type ValueOf<T> = T[keyof T];

export enum Application {
  MLDE = 'mlde',
  MLDM = 'mldm',
}

export enum Backend {
  EC2 = 'ec2',
  EKS = 'eks',
  GKE = 'gke',
  BYOK8S = 'byok',
}

export enum Location {
  AWS = 'aws',
  GCP = 'gcp',
  BYOK8S = 'byok',
}
