import { ColumnType } from 'antd/es/table';
import { useCallback, useEffect, useState } from 'react';

import Page from 'components/Page';
import ResponsiveTable from 'components/ResponsiveTable';
import { useStore } from 'contexts/Store';
import { useFetchWithRetry } from 'hooks/useFetch';
import { paths } from 'routes/utils';
import { fetchAllOrgs } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import { ModelListOrgsEntry } from 'services/global-bindings/api';
import handleError from 'utils/error';
import { routeToReactUrl } from 'utils/routes';
import { getOrganizationIdentifier } from 'utils/saas';
import { alphaNumericSorter } from 'utils/sort';

import css from './CustomerOrgs.module.scss';

const columns: ColumnType<GlobalApi.ModelListOrgsEntry>[] = [
  {
    dataIndex: 'id',
    defaultSortOrder: 'ascend',
    key: 'id',
    sorter: (a, b) => alphaNumericSorter(a.id, b.id),
    title: 'ID',
  },
  {
    dataIndex: 'name',
    defaultSortOrder: 'ascend',
    key: 'name',
    sorter: (a, b) => alphaNumericSorter(a.name, b.name),
    title: 'Name',
  },
  {
    dataIndex: 'ownerId',
    defaultSortOrder: 'ascend',
    key: 'ownerId',
    sorter: (a, b) => alphaNumericSorter(a.ownerId, b.ownerId),
    title: 'Owner',
  },
];

const CustomerOrgs: React.FC = () => {
  const {
    orgState: { selectedOrg },
  } = useStore();
  const [canceler] = useState(() => new AbortController());
  const [isLoading, setIsLoading] = useState(false);
  const [orgs, setOrgs] = useState<ModelListOrgsEntry[]>([]);
  const fetchWithRetry = useFetchWithRetry(canceler);
  const getAllOrgs = useCallback(async () => {
    try {
      const orgs = await fetchWithRetry(
        async () => await fetchAllOrgs(undefined, { signal: canceler.signal }),
      );
      setOrgs(orgs);
    } catch (e) {
      handleError(e, {
        publicSubject: `Failed to fetch ${getOrganizationIdentifier()}`,
      });
    } finally {
      setIsLoading(false);
    }
  }, [canceler, fetchWithRetry]);

  useEffect(() => {
    // Since the current tab becomes hidden in the sidebar when the selected org changes to a non-super-admin-org,
    // we are redirecting the user to clusters page.
    if (!selectedOrg?.isSuperAdminOrg) {
      routeToReactUrl(paths.clusters());
      return;
    }

    getAllOrgs();
  }, [getAllOrgs, selectedOrg]);

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  return (
    <Page title={`Customer ${getOrganizationIdentifier()}`}>
      <ResponsiveTable
        className={css.orgsTable}
        columns={columns}
        dataSource={orgs}
        loading={isLoading}
        pagination={{ hideOnSinglePage: true }}
        rowKey={(record) => record.id}
        scroll={{ x: 1000 }}
        showSorterTooltip={false}
        size="small"
      />
    </Page>
  );
};

export default CustomerOrgs;
