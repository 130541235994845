export const initialState = {
  auxiliaryResources: {},
  overallConfig: {
    detVersion: '',
    masterInstanceType: '',
  },
  poolConfigs: [],
  resourceManager: {
    default_aux_resource_pool: '',
    default_compute_resource_pool: '',
  },
};
