import { throwCreateClusterError } from 'pages/Clusters/cluster-util';
import { ModelCreateClusterRequest } from 'services/regional-bindings';

export const validateClusterRequestObject = (
  clusterRequest: ModelCreateClusterRequest | undefined,
): void => {
  if (!clusterRequest) {
    throwCreateClusterError('Failed to create cluster.');
  } else {
    if (!clusterRequest.name) {
      throwCreateClusterError('Cluster name required');
    }
    if (!clusterRequest.detVersion && !clusterRequest.appVersions) {
      throwCreateClusterError('Version required');
    }
  }
};
