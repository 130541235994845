import { Divider } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import Form from 'hew/Form';
import React, { useCallback } from 'react';

import InstanceTypeDropdown from 'components/InstanceTypeDropdown';
import Link from 'components/Link';
import ResourcePools from 'components/ResourcePools';
import VersionPicker from 'components/VersionPicker';
import { InstanceUse, OverallConfig } from 'saasTypes';
import { ModelMasterConfig } from 'services/regional-bindings';
import { SemanticVersion } from 'types';
import { stringToVersion, versionToString } from 'utils/string';

import NetworkingParamsPicker, {
  NetworkingParams,
  UpdateNetworkingParams,
} from './NetworkingParamsPicker';
import css from './NewAwsMldeClusterModal.module.scss';

interface AdvancedConfigFormProps {
  clusterRegion: string;
  networkingParams: Partial<NetworkingParams>;
  updateNetworkingParams: UpdateNetworkingParams;
  onUpdateOverallConfig: React.Dispatch<React.SetStateAction<OverallConfig>>;
  overallConfig: OverallConfig;
  updateMasterConfig: React.Dispatch<React.SetStateAction<ModelMasterConfig>>;
  supportedDetVersions: string[];
  form: FormInstance;
}

const AdvancedConfigForm: React.FC<AdvancedConfigFormProps> = ({
  overallConfig,
  onUpdateOverallConfig,
  supportedDetVersions,
  clusterRegion,
  updateMasterConfig,
  networkingParams,
  updateNetworkingParams,
  form,
}) => {
  const updateVersion = useCallback(
    (ver: SemanticVersion) => {
      onUpdateOverallConfig({ ...overallConfig, detVersion: versionToString(ver) });
    },
    [onUpdateOverallConfig, overallConfig],
  );

  const selectInstanceType = useCallback(
    (instanceType: string) => {
      onUpdateOverallConfig({
        ...overallConfig,
        ...{ masterInstanceType: instanceType },
      });
    },
    [onUpdateOverallConfig, overallConfig],
  );

  return (
    <>
      <Form
        form={form}
        initialValues={{
          detVersion: overallConfig.detVersion,
          masterInstanceType: overallConfig.masterInstanceType,
        }}
        labelCol={{ span: 24 }}>
        <Form.Item label="MLDE Version" name="mldeVersion">
          <VersionPicker
            versions={supportedDetVersions.map((v) => stringToVersion(v))}
            onChange={updateVersion}
          />
        </Form.Item>
        <Form.Item
          className={css.formItemWithLink}
          label={
            <>
              <span>Master Instance Type</span>
              <Link
                external={true}
                path="https://aws.amazon.com/ec2/instance-types/#General_Purpose"
                popout={true}>
                List of Instance Types
              </Link>
            </>
          }
          labelCol={{ span: 24 }}
          name="masterInstanceType">
          <InstanceTypeDropdown
            buttonLabel={overallConfig.masterInstanceType}
            clusterRegion={clusterRegion}
            id="masterInstanceType"
            use={InstanceUse.Master}
            onSelect={selectInstanceType}
          />
        </Form.Item>
      </Form>
      <NetworkingParamsPicker
        form={form}
        networkingParams={networkingParams}
        updateNetworkingParams={updateNetworkingParams}
      />
      <Divider />
      <ResourcePools updateMasterConfig={updateMasterConfig} />
    </>
  );
};

export default AdvancedConfigForm;
