import Select, { Option } from 'hew/Select';
import React, { useEffect, useMemo, useState } from 'react';

import { SemanticVersion } from 'types';
import { sortVersions } from 'utils/sort';
import { stringToVersion, versionToString } from 'utils/string';

/** render a list of versions for the user to select */
const VersionPicker: React.FC<{
  onChange: (version: SemanticVersion) => void;
  versions: SemanticVersion[];
}> = ({ onChange, versions }) => {
  const [curVal, setCurVal] = useState(
    versions.length ? versionToString(sortVersions(versions)[0]) : undefined,
  );

  useEffect(() => {
    if (curVal) onChange(stringToVersion(curVal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curVal]);

  const options = useMemo(() => {
    const opts = [...versions];
    return sortVersions(opts).map((version, idx) => {
      const ver = versionToString(version);
      const labelSuffix = idx === 0 ? ' (latest)' : '';
      return (
        <Option key={ver} value={ver}>
          {ver}
          {labelSuffix}
        </Option>
      );
    });
  }, [versions]);
  return (
    <span id="version-container" style={{ position: 'relative' }}>
      <Select
        attachDropdownToContainer={true}
        id="mldeVersion"
        value={curVal}
        onChange={(v) => setCurVal(v as string)}>
        {options}
      </Select>
    </span>
  );
};

export default VersionPicker;
